<template>
  <div class="container">

    <!-- Media. -->
    <div class="media">
      <div
        class="alt-image"
        :style="{'background-image':`url(${page.acf.hover_image_url.url}`}"
        :alt="page.acf.hover_image_url.alt || 'Team member alt text not set'"
        v-aspect-ratio="543 / 440"
      >
        <img
          v-if="page.featured_media_url"
          :src="page.featured_media_url"
          :alt="page.acf.hover_image_url.alt || 'Team member alt text not set'"
          class="img-hover"
        >
      </div>

      <PersonSocialNav
        class="social-nav"
        :instagram="page.acf.socials.instagram"
        :linkedin="page.acf.socials.linkedin"
        :twitter="page.acf.socials.twitter"
        :email="page.acf.socials.email"
      />
      <div
        class="m-no-socials-puller"
        v-if="!page.acf.socials.linkedin && !page.acf.socials.instagram"
      />
    </div>

    <!-- Details. -->
    <div class="details">
      <h1 v-html="page.title.rendered" />
      <p v-show="page.acf.role_long" class="roles">{{ page.acf.role_long }}</p>
      <div class="content" v-html="page.content.rendered"/>
    </div>

  </div>
</template>

<script>
import PersonSocialNav from './PersonSocialNav'

export default {
  props: {
    page: Object
  },
  components: {
    PersonSocialNav
  },
  created () {
    // console.log('@PersonContent->page', this.page)
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  display: flex;
  /* margin-top: 172px; */
  /* margin-bottom: 100px; */
  @media (max-width: 800px) {
    flex-direction: column;
    /* margin-top: 110px; */
    /* margin-bottom: 62px; */
  }
}

.media {
  margin-left: grid-pct(1);
  width: grid-pct(4);
  @media (max-width: 800px) {
    width: 100%;
    margin: 0px;
    .social-nav {
      @include m-margin(0.5);
    }
    .m-no-socials-puller {
      @media (max-width: 800px) {
        margin-bottom: -16px;
      }
    }
    img {
      margin-bottom: m-grid(1);
    }
  }
}

.alt-image {
  display: block;
  margin-bottom: 32px;
  width: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  .img-hover {
    width: 100%;
    height: calc(100% + 2px);
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.4s;
    &:hover {
      opacity: 0;
      transition: opacity 0.4s;
    }
  }
  @media (max-width: 800px) {
    margin-bottom: 62px;
  }
}

@include theme ('light') {
  .alt-image {
    background-color: $light-placeholder;
    @include theme-transition;
  }
}

@include theme ('dark') {
  .alt-image {
    @include theme-transition;
    background-color: $dark-placeholder;
  }
}

.details {
  margin-left: grid-pct(1);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-left: 0px;
    width: 100%;
  }
}

h1 {
  margin-top: 45px;
  margin-bottom: 16px;
  // font-size: 3rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t2;
  @media (max-width: 800px) {
    margin-top: 16px;
    @include m-margin(0.5);
    @include m-t2;
    margin-bottom: 24px;
  }
}

.roles {
  margin-top: 0;
  margin-bottom: 24px;
  max-width: 275px;
  font-family: $font-family-mono;
  // font-size: .875rem;
  // line-height: 1.5;
  letter-spacing: .1em;
  text-transform: uppercase;
  white-space: pre-line;
  opacity: .6;
  @include b3;
  line-height: px(22.4);
  line-height: 1.43 !important;
  @media (max-width: 800px) {
    @include m-margin(0.5);
    @include m-b3;
    margin-bottom: 32px;
  }
}

.content {
  font-size: 1.125rem;
  line-height: 1.5;
  @media (max-width: 800px) {
    @include m-margin(0.5);
  }
}

.content /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
    }
  }

  p + p {
    margin-top: 24px;
  }
}
</style>
