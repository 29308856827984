<template>
  <div class="container">
    <h2>{{this.roleByText.post_title}} by <span v-html="name" /></h2>

    <!-- The video Modal -->
    <VideoModal
      :active="videoModalActive"
      :embed_url="video.embed_url"
      :height="Number(video.height)"
      :width="Number(video.width)"
      @close="videoModalActive = false"
    />

    <!-- The Photography modal -->
    <PhotographyModal
      :active="imageModalActive"
      :images="images"
      @close="imageModalActive = false"
    />


    <WorkList
      :works="works"
      :openModal="openModal"
    />
  </div>
</template>

<script>
// import Categories from './Categories'
import Wordpress from '../mixins/Wordpress'
import WorkList from '../components/WorkList'
import VideoModal from '../components/VideoModal'
import PhotographyModal from '../components/PhotographyModal'

export default {
  mixins: [Wordpress],
  props: {
    name: String,
    works: Array,
    roleByText: {
      type: Object,
      default: function () {
        return { 'post_title': 'Worked on' }
      }
    }
  },
  data () {
    return {
      show: true,
      videoModalActive: false,
      imageModalActive: false,
      images: [],
      video: {
        embed_url: '',
        width: 640,
        height: 360
      }
    }
  },
  methods: {
    openModal (work) {
      if (this.$wp.workTypes.modal.vimeo === work?.acf?.modal_type) {
        this.video = work?.acf?.vimeo || {}
        this.videoModalActive = true
      } else if (this.$wp.workTypes.modal.photography === work?.acf?.modal_type) {
        this.images = work?.acf?.photography || []
        this.imageModalActive = true
      } else {
        console.warn(`@PresonDirected->openModal unsuported modal type of ${work?.acf?.modal_type}`)
      }
    }
  },
  components: {
    WorkList,
    VideoModal,
    PhotographyModal
    // Categories
  },
  created () {
    // console.log(this)
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";
@import "../styles/theme";

.container {
  @extend %container;
  @extend %mobile-container;
  /* margin-bottom: 220px; */
  @media (max-width: 800px) {
    /* margin-bottom: 0px; */
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 45px;
  margin-left: d-grid(0.5, -12px);
  width: grid-pct(6);
  // font-size: 2.25rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t3;
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 32px;
    @include m-margin(0.5);
    @include m-t3;
  }
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: grid-pct(1);
  margin-bottom: -45px;
  padding: 0;
  width: grid-pct(10);
  list-style: none;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 115px;
  }
}

li {
  width: 47.5%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

@include theme('light') { .project-link { color: black; } }
@include theme('dark') { .project-link { color: white; } }

.project-link {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
  letter-spacing: -.01em;
  @include b1;
  text-decoration: none;
  @media (max-width: 800px) {
    @include m-b1;
  }
}

@include link-text-hover-underline('.project-link', 0px, inherit, inherit);
@media (max-width: 800px) {
  @include link-text-hover-underline('.project-link', 0px, inherit, inherit);
}

img {
  display: block;
  margin-bottom: 24px;
  width: 100%;
  object-fit: cover;
}

.categories {
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.excerpt {
  margin-top: 16px;
  margin-bottom: 45px;
  font-size: 1.125rem;
  line-height: 1.5;
}

li:last-of-type {
  .excerpt {
    @media (max-width: 800px) {
      margin-bottom: 0px;
    }
  }
}

.excerpt /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
    }
  }

  p + p {
    margin-top: 24px;
  }
}
</style>
