<template>
<div>

  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader />
    </div>
  </transition>

  <!-- Content. -->
  <div
    v-if="page"
    class="page-box moving-background content person"
    ref="about"
  >
    <PersonContent 
      class="PersonContent"
      :page="page" 
    />
    
    <PersonDirected
      class="PersonDirected"
      v-if="page.acf.directed_works"
      :name="shortName"
      :works="page.acf.directed_works"
      :roleByText="page.acf.role_by_text"
    />

    <PersonSurvey
      class="PersonSurvey"
      v-if="page.acf.survey"
      :name="shortName"
      :survey="page.acf.survey"
    />

    <!-- Footer -->
    <Contact2021 
      class="Contact2021"
      :contact="page.acf.contact" 
      v-if="page.acf.contact && page.acf.contact.contact_blurb && page.acf.contact.subtitle && page.acf.contact.title"
    />
    <SubscribeForm2021
      class="SubscribeForm2021"
    />
    <CopyrightFooter
      class="CopyrightFooter"
      v-if="copyright && copyright !== ''"
      :copyright="copyright"
    />

  </div>
  
</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import PersonContent from '../components/PersonContent'
import PersonSurvey from '../components/PersonSurvey'
import PersonDirected from '../components/PersonDirected'
import Loader from '../components/Loader'
import throttle from 'lodash/throttle'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import SubscribeForm2021 from '../components/SubscribeForm2021'
import Contact2021 from '../components/Contact2021'
import CopyrightFooter from '../components/CopyrightFooter'
// import BgCross from '../components/BgCross'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true,
      page: null,
      shortName: '',
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null
    }
  },
  watch: {
    '$matchedRoute.pathResolved' () {
      this.fetchPage()
    }
  },
  computed: {
    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    },
    showContact () {
      return (
        this.page.acf.contact.contact_blurb && this.page.acf.contact.subtitle && this.page.acf.contact.title && this.page.acf.contact.contact_blurb !== '' && this.page.acf.contact.subtitle !== '' && this.page.acf.contact.title !== ''
      )
    }
  },
  methods: {
    async fetchPage () {
      if (this.$route.query.preview) {
        this.page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        this.page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }
      // console.log('@Person->page', this.page)
      /**
       * Convert the name in the page title in to just the first name.
       * If we have an issue with it then fall back to the full name
       * in the title.
       */
      this.shortName = this.page.title.rendered.split(' ')[0]
      if (this.shortName === undefined || this.shortName === null || this.shortName.length < 1) {
        this.shortName = this.page.title.rendered
      }

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(this.$refs.about, 30, {
                backgroundPositionY: '0%'
              }, {
                backgroundPositionY: '100%'
              })
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration(document.body.scrollHeight)
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchPage()
    })
  },
  components: {
    // CloseButton,
    PersonContent,
    PersonSurvey,
    PersonDirected,
    Loader,
    SubscribeForm2021,
    Contact2021,
    CopyrightFooter
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/container';
  @import '../styles/buttons';
  @import '../styles/grid';
  @import "../styles/text";
  @import "../styles/variables";

  .loading {
    @include loading;
  }
  @include loading-page-fade;

  .page-box {
    padding-top: 175px;
    padding-bottom: 110px;

    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  .moving-background {
    background-image: url('./../assets/team/bg.svg');
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: 266.3px 1230.28px;
    @media (max-width: 800px) {
      background-image: none;
    }
  }

  /* .PersonContent {} */

  .PersonDirected {
    margin-top: 80px;
  }

  .PersonContent + .PersonSurvey {
    margin-top: 100px;
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }


  .PersonDirected + .PersonSurvey {
    margin-top: 50px;
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }

  .Contact2021 {
    margin-top: 130px;
    @media (max-width: 800px) {
      margin-top: 110px;
    }
  }

  .SubscribeForm2021 {
    margin-top: 60px;
  }
  
  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }
</style>
