<template>
  <div class="container">
    <ul>
      <li v-for="(item, i) in survey" :key="i">
        <p class="question">{{ item.question }}</p>
        <div class="answer" v-html="item.answer" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    survey: Array,
    name: String
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  /* margin-bottom: 165px; */
  @media (max-width: 800px) {
    /* margin-bottom: 93px; */
  }
}

h2 {
  @include t3;
  &.m-show {
    display: block !important;
  }
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 32px;
    @include m-margin(0.5);
    @include m-t3;
  }
}
ul {
  margin-left: grid-pct(3.5);
  padding: 0;
  width: grid-pct(5);
  list-style: none;
   @media (max-width: 800px) {
      @include m-margin(0.5);
   }
}

li + li {
  // margin-top: 32px;
}

.question {
  margin-top: 0;
  margin-bottom: 18px;
  color: $red;
  @include b2;
  line-height: px(25);
  @media (max-width: 800px) {
      @include m-b2;
  }
}

.answer {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 32px;
}

@include link-text-hover-underline('.answer /deep/ a', 0px);

.answer /deep/ {
  p {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    @include b2;
    line-height: px(25);
    margin-bottom: 18px;
    @media (max-width: 800px) {
       @include m-b2;
       margin-bottom: 24px;
    }
  }

  p + p {
    margin-bottom: 16px;
  }

  img {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    max-width: 440px;
    width: 100%;
    height: auto;
  }

  .wp-video {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  video {
    display: block;
    max-width: 440px;
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  iframe {
    display: block;
    max-width: 440px;
    width: 100%;
    height: 440px !important;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
